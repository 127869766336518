import { graphql, Link } from "gatsby";
import * as React from "react";
import Layout from "../components/layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import Fuse from "fuse.js";
import { HTMLContent } from "../components/common/Content";
import List from "../components/guides/List";

// props
interface Props {
  data: {
    guides: {
      nodes: Array<{
        frontmatter: {
          description: string;
          title: string;
          githubUrl?: string;
        };
        fields: {
          path: string;
        };
      }>;
    };
  };
}

// markup
const GuidesTemplate = (props: Props) => {
  // const guides = splitGuides(props.data.guides.edges)
  const [search, setSearch] = React.useState<any>();
  const [query, setQuery] = React.useState("");
  const [result, setResult] = React.useState<any>([]);

  React.useEffect(() => {
    const options = {
      includeScore: true,
      threshold: 0.3,
      keys: [
        { name: "frontmatter.title", weight: 1 },
        { name: "frontmatter.description", weight: 1 },
      ],
    };
    const fuse = new Fuse(props.data.guides.nodes, options);
    setSearch(fuse);
  }, [props.data.guides.nodes]);

  React.useEffect(() => {
    const onSearch = () => {
      if (!search) {
        return;
      }
      let vals = props.data.guides.nodes;
      if (query.trim()) {
        vals = search.search(query).map((val: any) => val.item);
      }
      setResult(vals);
    };
    onSearch();
  }, [query, search]);

  return (
    <Layout
      className="guides"
      seo={{
        title: "Guides",
      }}
    >
      <div className="has-background-light">
        <div className="container">
          <div className="hero hero-guides">
            <div className="content">
              <h1 className="is-size-1">Guides</h1>
            </div>
          </div>
          <div className="container pb-6">
            <div className="description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
              sapiente ea blanditiis placeat unde, tempore non temporibus a sed?
              Dicta obcaecati maiores quibusdam minus fuga quisquam eius
              similique eum eligendi?
            </div>
            <div className="">
              <div className="search">
                <input
                  className="input is-special"
                  placeholder="Find a guide"
                  value={query}
                  onChange={(event) => {
                    setQuery(event.target.value);
                  }}
                  autoComplete="off"
                />
                <span className="icon">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
            </div>
            {result?.length > 0 ? (
              <List icon="guide.svg" items={result} />
            ) : (
              <div className="pt-6 pb-6 has-text-centered has-text-weight-bold">
                No result found.
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GuidesTemplate;

// graphql Guides query
export const pageQuery = graphql`
  query GuidesPage {
    guides: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "guide-template" } } }
    ) {
      nodes {
        frontmatter {
          title
          description
          keywords
          slug
          githubUrl
        }
        fields {
          path
        }
      }
    }
  }
`;
